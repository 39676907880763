import Soton from "../assets/Education/Soton.png";
import CTGU from "../assets/Education/CTGU.png";
import RadicalAI from "../assets/Work/Radical AI.png";
import JoinMomentum from "../assets/Work/Join Momentum.png";
import CSS3 from "../assets/Skills/CSS3.png";
import HTML5 from "../assets/Skills/HTML5.png";
import JavaScript from "../assets/Skills/JavaScript.png";
import Premiere from "../assets/Skills/Premiere.svg";
import Python from "../assets/Skills/Python.svg";
import LogicPro from "../assets/Skills/LogicPro.png";
import React from "../assets/Skills/React.svg";
import Redux from "../assets/Skills/Redux.png";
import Vue from "../assets/Skills/Vue.svg";
import Git from "../assets/Skills/Git.png";
import Figma from "../assets/Skills/Figma.svg";
import FinalCutPro from "../assets/Skills/FinalCutPro.png";
import Less from "../assets/Skills/Less.png";
import SASS from "../assets/Skills/Sass.png";
import TypeScript from "../assets/Skills/TypeScript.svg";
import NextJS from "../assets/Skills/NextJS.svg";
import NodeJS from "../assets/Skills/NodeJS.png";
import MongoDB from "../assets/Skills/MongoDB.png";
import Flask from "../assets/Skills/Flask.png";
import Tailwind from "../assets/Skills/Tailwind.png";
import Gericht from "../assets/Projects/Gericht.png";
import NewBlog from "../assets/Projects/NewBlog.png";
import ClearVision from "../assets/Projects/ClearVision.png";
import FindAService from "../assets/Projects/FindAService.png";
import HireDirect from "../assets/Projects/HireDirect.png";
import FindAServiceUI from "../assets/Projects/FindAServiceUI.png";
import CozyHaven from "../assets/Projects/CozyHaven.png";
import JobSimulatorAIPlatform from "../assets/Projects/JobSimulatorAIPlatform.png";
import MarvelAIPlatform from "../assets/Projects/MarvelAIPlatform.png";
import SongList from "../assets/Projects/SongList.png";
import YMTS from "../assets/Projects/YMTS.png";
import Navigation from "../assets/Projects/Navigation.png";
import Docsify from "../assets/Projects/Docsify.png";
import Portfolio from "../assets/Projects/Portfolio.png";
import RezaHomayuni from "../assets/Testimonials/Reza Homayuni.jpeg";
import LeonardFarmer from "../assets/Testimonials/Leonard Farmer.jfif";
import KylePadayachee from "../assets/Testimonials/Kyle Padayachee.jpeg";
import BetiniAkarandut from "../assets/Testimonials/Betini Akarandut.jpeg";
import EnterpriseDesignThinkingPractitioner from "../assets/Certifications/Enterprise_Design_Thinking_Practitioner_Badge20240904-7-4vscq6.png";
import CertificateofCompletionatRadicalAI from "../assets/Certifications/Certificate of Completion at Radical AI.pdf.png";
import JiraCertificate from "../assets/Certifications/Jira Certificate.png";
import ConfluenceCertificate from "../assets/Certifications/Confluence Certificate.png";
import SecondPrizeLanQiaoCup from "../assets/Certifications/Second Prize in C:C++ Programming Competition at Lan Qiao Cup.png";
import FirstPrizeLanQiaoCup from "../assets/Certifications/First Prize in C:C++ Programming Competition at Lan Qiao Cup.png";
import SecondPrizeFinance from "../assets/Certifications/Second Prize in the 2020 National Financial and Securities Investment Simulation Training Competition.png";
import ScrumforOpsandDevOpsFundamentalsCertified from "../assets/Certifications/ScrumforOpsandDevOpsFundamentalsCertified-ShisongHuang-1011840.png";
import TrelloFundamentalsBadge from "../assets/Certifications/Trello Fundamentals Badge.png";
import ScrumFundamentalsCertified from "../assets/Certifications/ScrumFundamentalsCertified-ShisongHuang-1042636.png";
import PythonBasicCertificate from "../assets/Certifications/python_basic-certificate.png";
import ProblemSolvingIntermediateCertificate from "../assets/Certifications/problem_solving_intermediate certificate.png";
import ProblemSolvingBasicCertificate from "../assets/Certifications/problem_solving_basic-certificate.png";
import SoftwareEngineerCertificate from "../assets/Certifications/software_engineer certificate.png";
import CSSBasicCertificate from "../assets/Certifications/css-certificate.png";
import FrontendDeveloperReactCertificate from "../assets/Certifications/frontend_developer_react certificate.png";
import JavaScriptBasicCertificate from "../assets/Certifications/javascript_basic-certificate.png";
import ReactBasicCertificate from "../assets/Certifications/react_basic-certificate.png";
import DSAJS from "../assets/Blogs/DSA JS.png";
import LeetCodeJS from "../assets/Blogs/LeetCode JS.png";
import NewDesignAuthorCard from "../assets/Blogs/NewDesignAuthorCard.png";
import NewBlogPost from "../assets/Blogs/NewBlog.png";

import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedinIn, FaLinkedin, FaBloggerB } from "react-icons/fa";
import { TbBrandGithubFilled, TbFileCv } from "react-icons/tb";

const info = {
  jobTitles: [
    "Frontend Developer 🖥️",
    "Software Engineer 💻",
    "Content Creator 📰",
    "Cover Singer 🎤",
  ],
  description:
    "An innovative and detail-oriented recent postgraduate with a solid 5-year computer background and work experience as a software developer. Enjoy building beautiful, user-friendly, and high-performance software that brings ideas to life.",
  sections: [
    "about",
    "skills",
    "education",
    "work",
    "projects",
    "blogs",
    "certifications",
    "testimonials",
    "contact",
  ],
  socialLinks: {
    LinkedIn: {
      link: "https://www.linkedin.com/in/harriswong89/",
      icon: <FaLinkedin />,
    },
    about: [
      {
        name: "CV / Resume",
        icon: <TbFileCv />,
        link: "https://drive.google.com/file/d/1_vkxhUc0-l68a1o2ITQXF54nTlozKoCC/view?usp=drive_link",
      },
      {
        name: "Blog",
        icon: <FaBloggerB />,
        link: "https://blog.harriswong.top/",
      },
      {
        name: "GitHub",
        icon: <TbBrandGithubFilled />,
        link: "https://github.com/harrisblog",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/harriswong89/",
        icon: <FaLinkedinIn />,
      },
    ],
    footer: [
      {
        name: "CV / Resume",
        icon: <TbFileCv />,
        link: "https://drive.google.com/file/d/1_vkxhUc0-l68a1o2ITQXF54nTlozKoCC/view?usp=drive_link",
      },
      {
        name: "Blog",
        icon: <FaBloggerB />,
        link: "https://blog.harriswong.top/",
      },
      {
        name: "GitHub",
        icon: <TbBrandGithubFilled />,
        link: "https://github.com/harrisblog",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/__hss0809__/",
        icon: <RiInstagramFill />,
      },
    ],
  },
  skills: [
    {
      name: "HTML5",
      themeColor: "#f06529",
      img: HTML5,
      percentage: 85,
    },
    {
      name: "CSS3",
      themeColor: "#2965f1",
      img: CSS3,
      percentage: 85,
    },
    {
      name: "SASS/SCSS",
      themeColor: "#c69",
      img: SASS,
    },
    {
      name: "Less",
      themeColor: "#1d365d",
      img: Less,
    },
    {
      name: "Tailwind CSS",
      themeColor: "#39bef8",
      img: Tailwind,
    },
    {
      name: "JavaScript",
      themeColor: "#e7d038",
      img: JavaScript,
      percentage: 80,
    },
    {
      name: "TypeScript",
      themeColor: "#3178c6",
      img: TypeScript,
    },
    {
      name: "React",
      themeColor: "#0074a6",
      img: React,
      percentage: 80,
    },
    {
      name: "Redux",
      themeColor: "#704cb6",
      img: Redux,
    },
    {
      name: "Next.js",
      themeColor: "#000",
      img: NextJS,
    },
    {
      name: "Node.js",
      img: NodeJS,
    },
    {
      name: "MongoDB",
      themeColor: "#3FA037",
      img: MongoDB,
    },
    {
      name: "Figma",
      img: Figma,
    },
    {
      name: "Vue",
      img: Vue,
    },
    {
      name: "Git",
      themeColor: "#df5c3e",
      img: Git,
    },
    {
      name: "Python",
      img: Python,
    },
    {
      name: "Flask",
      img: Flask,
      themeColor: "#999",
    },
    {
      name: "Premiere",
      themeColor: "#9999f8",
      img: Premiere,
    },
    {
      name: "Logic Pro",
      themeColor: "#404040",
      img: LogicPro,
    },
    {
      name: "Final Cut Pro",
      themeColor: "#404040",
      img: FinalCutPro,
    },
  ],
  education: [
    {
      tags: ["Sep. 2022 - Nov. 2023", "Southampton, UK"],
      institution: "University of Southampton",
      link: "https://www.southampton.ac.uk/",
      subtitle: "MSc in Software Engineering with Merit (2:1)",
      logo: Soton,
      logoStyle: {
        height: "75%",
        width: "75%",
        transform: "translateY(2px)",
      },
    },
    {
      tags: ["Sep. 2018 - Jun. 2022", "Yichang, Hubei, CN"],
      institution: "China Three Gorges University",
      link: "https://www.ctgu.edu.cn/",
      subtitle: "BSc in Computer Science (85/100)",
      logo: CTGU,
    },
  ],
  work: [
    {
      tags: ["Jul. 2024 - Nov. 2024", "Remote - London, UK", "Internship"],
      institution: "Join Momentum",
      link: "https://www.joinmomentum.co.uk/",
      jobTitle: "Frontend Developer",
      logo: JoinMomentum,
      logoStyle: {
        height: "75%",
        width: "75%",
      },
      desc: [
        "Developed 20+ pages for the Job Simulation platform using React, Next.js, TypeScript, Ant Design, and Tailwind CSS.",
        "Refactored legacy code by encapsulating 10+ reusable components and page templates, reducing code by 30%, and significantly improving scalability, maintainability, and efficiency for future iterations.",
        "Implemented dynamic task forms, enabling business users to customize task details. Used Formik for form state management and Yup for dynamic validation rules. Leveraged React Query for caching and state management.",
        "Collaborated with backend developers to optimize RESTful APIs, reducing frontend processing complexity. Reviewed and merged 10+ PRs, conducted frontend technical interviews, and mentored new interns for faster onboarding.",
      ],
    },
    {
      tags: ["Jan. 2024 - Jul. 2024", "Remote - New York, USA", "Internship"],
      institution: "Radical AI",
      link: "https://lab.radicalai.app/",
      jobTitle: "Software Developer",
      logo: RadicalAI,
      desc: [
        "Developed an AI Teaching Assistant platform using React, Redux, Next.js, and Firebase, supporting 300+ educators.",
        "Delivered 10+ features, including custom MUI components for educational scenarios (Notification, Text Field, etc), onboarding workflows using *react-hook-form-mui*, YouTube Flashcards generation, and quiz generation from document.",
        "Created custom hooks to manage route logic, redirecting unauthenticated or non-onboarded users to relevant pages.",
      ],
    },
  ],
  projects: [
    {
      type: "Featured",
      desc: "These are some featured personal web projects.",
      data: [
        {
          title: "Portfolio",
          img: Portfolio,
          demo: "https://harriswong.top",
          desc: "A website to showcase my skills and projects.",
          techs: ["React", "CSS", "JS"],
        },
        {
          title: "New Blog",
          img: NewBlog,
          demo: "https://blog.harriswong.top",
          desc: "A brand new blog that I built in late 2023. I mainly use it to record notes from my studies, bits and pieces of my life and to share some experiences and ideas.",
          techs: ["React", "SCSS", "NextJS", "TS"],
        },
        {
          title: "ClearVision",
          img: ClearVision,
          demo: "https://cv.harriswong.top",
          desc: "A CV builder website that allows users to create their CVs using predefined templates and also integrates with ChatGPT to provide CV smart optimisation and CV content generation.",
          techs: ["Python", "React", "Redux", "Less", "JS", "MUI"],
        },
        {
          title: "Find A Service",
          img: FindAService,
          demo: "https://fas.harriswong.top",
          desc: "A website that allows users to search for nearby services, such as cleaning, babysitting, pest control, plumbing, etc.",
          techs: ["MERN", "Ant Design"],
        },
        {
          title: "Gericht Restaurant",
          img: Gericht,
          demo: "https://gericht.harriswong.top",
          github: "https://github.com/harrisblog/Gericht_Restaurant",
          desc: "This is a restaurant website that I built following a tutorial by JavaScript Mastery on youtube.",
          techs: ["React", "CSS", "JS"],
        },
      ],
    },
    {
      type: "Work",
      desc: "These are some projects I worked on for various companies.",
      data: [
        {
          title: "Job Simulator AI Platform",
          img: JobSimulatorAIPlatform,
          demo: "https://jobsimulator.ai/",
          desc: "JobSimulation.ai aims to revolutionize the candidate assessment process by leveraging artificial intelligence to create immersive job simulation experiences. The platform streamlines the assessment process, allowing users to submit simulation activities and receive feedback tailored to their chosen job type, skill area, company, and scenario.",
          techs: ["React", "TS", "NextJS", "Tailwind CSS"],
        },
        {
          title: "Marvel AI Platform",
          img: MarvelAIPlatform,
          demo: "https://app.marvelai.org/",
          github: "https://github.com/radicalxdev/marvel-platform",
          desc: "Marvel Platform is engineered to augment the educational ecosystem by supporting instructors with intelligent program design and learner engagement strategies.",
          techs: ["React", "Redux", "NextJS", "MUI", "Firebase"],
        },
      ],
    },
    {
      type: "UI Design",
      desc: "These are the UIs I designed with Figma, including web and mobile.",
      data: [
        {
          title: "Hire Direct",
          img: HireDirect,
          demo: "https://www.figma.com/file/HiKiioqPnhyY6dRbE7C4Eg/Hire-Direct",
          desc: "An app that offers car rental services.",
          techs: ["Figma", "Mobile"],
        },
        {
          title: "Find A Service",
          img: FindAServiceUI,
          demo: "https://www.figma.com/file/Lk2rWzz8sd4NBkE5gF7vbJ/Find-A-Service?type=design&t=aSIqRdrDFamBAE85-1",
          desc: "A website that allows users to search for nearby services, such as cleaning, babysitting, pest control, plumbing, etc.",
          techs: ["Figma", "Web"],
        },
        {
          title: "CozyHaven",
          img: CozyHaven,
          demo: "https://www.figma.com/file/4UqOG2dpv2VJDAdfwLF15Q/CozyHaven?type=design&t=aSIqRdrDFamBAE85-1",
          desc: "A house looking platform that allows users to search the most suitable living area.",
          techs: ["Figma", "Web"],
        },
      ],
    },
    {
      type: "Hobby",
      desc: "In some of these web projects, I mainly modified lots of CSS code to make them more beautiful and friendly to use. Others were built while I was learning the technology.",
      data: [
        {
          title: "Old Blog",
          img: "https://cdn.jsdelivr.net/npm/hassan-assets/img/siteshot.jpg",
          demo: "https://old-blog.harriswong.top",
          desc: "A Hexo Butterfly theme blog. I mainly use it to record notes from my studies, bits and pieces of my life and to share some experiences and ideas.",
          techs: ["Hexo", "Butterfly Theme"],
        },
        {
          title: "Music Box",
          img: "",
          demo: "https://mb.harriswong.top/#/discovery",
          github: "https://github.com/harrisblog/MusicBox",
          desc: "A music player based on the Vue framework to call the NetEase cloud interface. (But now the API is invalid)",
          techs: ["Vue", "CSS", "JS", "Element UI"],
        },
        {
          title: "Song List",
          img: SongList,
          demo: "https://sl.harriswong.top/",
          github: "https://github.com/harrisblog/SongList",
          desc: "A static music player based on Aplayer and MetingJS.",
          techs: ["HTML", "CSS", "JS"],
        },
        {
          title: "YMTS",
          img: YMTS,
          demo: "https://ymts.vercel.app/home",
          github: "https://github.com/harrisblog/YMTS",
          desc: "A study abroad agency website. (Since there is no cloud server to provide back-end services, the pages that need to use back-end data have been removed temporarily)",
          techs: ["Vue", "CSS", "JS", "Element Plus", "Echarts"],
        },
        {
          title: "Navigation",
          img: Navigation,
          demo: "https://navi.harriswong.top",
          github: "https://github.com/harrisblog/navigation",
          desc: "A navigation website that includes links that I usually visit.",
          techs: ["Hexo", "Webstack Theme"],
        },
        {
          title: "Docsify",
          img: Docsify,
          demo: "https://doc.harriswong.top",
          github: "https://github.com/harrisblog/docsify",
          desc: "Online document website based on docsify.",
          techs: ["docsify"],
        },
      ],
    },
  ],
  blogs: [
    {
      title: "Practice LeetCode in JavaScript",
      img: LeetCodeJS,
      bgColor: "#e9d69c",
      textColor: "#ce8b0e",
      link: "https://blog.harriswong.top/posts/l3c3i1j0",
    },
    {
      title: "Data Structures & Algorithms in JavaScript",
      img: DSAJS,
      bgColor: "#053239",
      textColor: "#81a8af",
      link: "https://blog.harriswong.top/posts/d4s3a2j7",
    },
    {
      title: "Fabulous New Design on the Author Card of My Blog",
      img: NewDesignAuthorCard,
      bgColor: "#000",
      textColor: "#fff",
      link: "https://blog.harriswong.top/posts/f4n1a3b0",
    },
    {
      title: "I Rebuilt My Blog Site (Next.js, TS)",
      img: NewBlogPost,
      bgColor: "#caeeeb",
      textColor: "#1fb3a4",
      link: "https://blog.harriswong.top/posts/w3bg0ws9",
    },
  ],
  certifications: [
    {
      name: "Confluence Certificate",
      img: ConfluenceCertificate,
      tags: ["Project Management"],
    },
    {
      name: "Jira Certificate",
      img: JiraCertificate,
      tags: ["Project Management"],
    },
    {
      name: "Certificate of Internship Completion at Radical AI",
      img: CertificateofCompletionatRadicalAI,
      tags: ["Work"],
    },
    {
      name: "Enterprise Design Thinking Practitioner",
      img: EnterpriseDesignThinkingPractitioner,
      tags: ["Problem Solving"],
    },
    {
      name: "Scrum for Ops and DevOps Fundamentals Certified",
      img: ScrumforOpsandDevOpsFundamentalsCertified,
      tags: ["Project Management"],
    },
    {
      name: "Trello Fundamentals Badge",
      img: TrelloFundamentalsBadge,
      tags: ["Project Management"],
    },
    {
      name: "Scrum Fundamentals Certified (SFC)",
      img: ScrumFundamentalsCertified,
      tags: ["Project Management"],
    },
    {
      name: "Python (Basic) Certificate",
      img: PythonBasicCertificate,
      tags: ["Backend"],
    },
    {
      name: "Problem Solving (Intermediate) Certificate",
      img: ProblemSolvingIntermediateCertificate,
      tags: ["Problem Solving", "Data Structures & Algorithms"],
    },
    {
      name: "Problem Solving (Basic) Certificate",
      img: ProblemSolvingBasicCertificate,
      tags: ["Problem Solving", "Data Structures & Algorithms"],
    },
    {
      name: "Software Engineer Certificate",
      img: SoftwareEngineerCertificate,
      tags: ["Role"],
    },
    {
      name: "CSS (Basic) Certificate",
      img: CSSBasicCertificate,
      tags: ["Frontend"],
    },
    {
      name: "Frontend Developer (React) Certificate",
      img: FrontendDeveloperReactCertificate,
      tags: ["Frontend", "Role"],
    },
    {
      name: "JavaScript (Basic) Certificate",
      img: JavaScriptBasicCertificate,
      tags: ["Frontend"],
    },
    {
      name: "React (Basic) Certificate",
      img: ReactBasicCertificate,
      tags: ["Frontend"],
    },
    {
      name: "Second Prize in C/C++ Programming Competition at Lan Qiao Cup",
      img: SecondPrizeLanQiaoCup,
      tags: ["Problem Solving", "Data Structures & Algorithms", "Competition"],
    },
    {
      name: "First Prize in C/C++ Programming Competition at Lan Qiao Cup",
      img: FirstPrizeLanQiaoCup,
      tags: ["Problem Solving", "Data Structures & Algorithms", "Competition"],
    },
    {
      name: "Second Prize in the 2020 National Financial and Securities Investment Simulation Training Competition",
      img: SecondPrizeFinance,
      tags: ["Finance", "Competition"],
    },
  ],
  testimonials: [
    {
      name: "Betini Akarandut",
      img: BetiniAkarandut,
      themeColor: "rgba(132, 131, 47, .3)",
      title: "Backend Developer @ Join Momentum",
      comment: `It has been an absolute pleasure working alongside Shisong Huang at Join Momentum. Shisong consistently impressed me with his expertise in frontend development and his commitment to delivering high-quality results. His collaborative spirit made working together seamless, bridging the backend and frontend in a way that brought out the best in our team. Beyond technical skills, Shisong demonstrated a deep sense of integrity, inclusivity, and professionalism that truly enriched our work environment.

I genuinely admire his approach to growth and teamwork, as well as his strong moral values, which have inspired me personally. Shisong, I will miss having you on our team, but I'm confident that your journey ahead will be filled with success and new opportunities. Wishing you all the best on your next adventure!`,
    },
    {
      name: "Kyle Padayachee",
      img: KylePadayachee,
      themeColor: "rgba(55, 116, 235, .3)",
      title: "Software Support Engineer @ Radical AI",
      comment: `Shisong has done very well in his team in the Kai/Marvel project has contributed various design flows as a software engineer and offering back-end support to his fellow teammates. His proficiency in React.js, Redux, Material Ul and Firebase functions towards the project has shown appraisal with his line managers. His ability in pair programming and communication in his team is a must and fosters strong work ethic and effective communication.

I would highly recommend Shisong as he serves as a great asset to the company or services he renders as an individual.`,
    },
    {
      name: "Leonard Farmer",
      img: LeonardFarmer,
      themeColor: "rgba(131, 126, 85, .4)",
      title: "Frontend Developer @ Join Momentum",
      comment: `I had the pleasure of working with Shisong at Joinmomentum, where he served as my mentor while working as a front-end developer. From the start, he has shown exceptional knowledge in React, TypeScript, Next.js, and other technologies. In a short time, he helped me grasp complex concepts, making it easier for me to develop my skills.

Shisong is always open to questions and provides clear, constructive feedback. He maintains a positive and encouraging attitude, which creates a great learning environment. He consistently communicates with me about my progress, ensuring I feel supported in my work.

I highly recommend Shisong to any employer looking for a skilled front-end / full-stack developer and a dedicated mentor. His impact on our team has been significant, and I am truly grateful for his guidance.`,
    },
    {
      name: "Reza Homayuni",
      img: RezaHomayuni,
      themeColor: "rgba(118, 86, 53, .35)",
      title: "Software Developer @ Radical AI",
      comment: `I had the pleasure of leading Shisong and working closely with him. As a frontend developer, he excels at building reusable user interface components and implementing complex business logic. Shisong's ability to explain technical concepts clearly, combined with his eagerness to learn new technologies, made him an invaluable asset to our team. His impressive adaptability allowed him to seamlessly contribute to our large open-source codebase.

I highly recommend Shisong for any frontend developer position.`,
    },
    {
      name: "You Could Be Next",
      img: null,
      title: `SDE @ Big Tech Company`,
      comment:
        "Working with Shisong was a game-changer! Your feedback here could inspire the next big innovation. Ready to share your thoughts?",
    },
  ],
};

export default info;
